.storage-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;


  .selecting-market-point-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    @media screen and (max-width: 850px) {
      flex-direction: column;
    }

    .header-left {
      display: flex;
    }

    /* Import area */
    .header-right {
      display: flex;
      align-items: center;
      font-size: 14px;
      border: 1px dotted rgba(153, 120, 226, 0.25);
      border-radius: 3px;
      margin-right: 20px;
      padding: 5px 15px;
      @media screen and (max-width: 850px) {
        width: fit-content;
        padding: 5px 15px;
        margin: 10px 0 0 20px;
      }

      .selects-section {
        display: flex;
        margin-right: 10px;


        .formatFile-select {
          font-size: 14px;
          color: rgba(46, 43, 43, 0.75);
          height: 40px;
          border-bottom: none;
          padding: 0 2px;
          text-align: center;

          .MuiSelect-select {
            border: 1px solid rgba(17, 17, 17, 0.15);
            border-radius: 5px;
            height: 16px;
            padding-left: 8px;
            box-sizing: content-box;
            &:focus {
              background-color: inherit;
            }
          }
        }

      }


      .import-button {
        background-color: rgba(17, 17, 17, 0.05);
        //margin-top: 3px;
        display: flex;
        align-items: center;
        //margin-left: auto;
        height: 32px;
        padding: 5px 12px;
        border-radius: 3px;
        cursor: pointer;
        text-decoration: none;
        border: 1px solid rgba(17, 17, 17, 0.15);

        &:hover {
          background-color: rgba(204, 204, 204, 0.3);
        }

        p {
          color: #000;
        }

        img {
          margin-top: 1px;
          margin-right: 10px;
          height: 12px;
        }
      }
    }

    /* << */
  }

  .not-selected-shop {
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px solid #5b5d65a2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;

    .centered-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      color: #5b5d65;
      letter-spacing: 1px;
      font-size: 20px;
      opacity: 0.8;
    }
  }
}

.storage-page-loading-container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
