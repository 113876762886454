@import "src/styles/variables.scss";



.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;

  &__top {
    width: 100%;
    height: 56px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 16px 1px rgba(69, 77, 88, 0.4);

    .w344 {
      width: 344px;
    }

    .w100 {
      width: 100px;
    }

    .logo {
      max-height: 80%;
      max-width: 244px;
      margin-left: 5px;

      @media screen and (max-width: 1024px) {
        max-height: 90%;
        max-width: 90px;
      }
    }

    &__blackArea {
      display: flex;
      flex-direction: row;
      background-color: $main_bg-black;
      height: 100%;

      &_logo {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #212628;
        cursor: pointer;

        img {
          height: 80%;
        }
      }

      &_h1 {
        width: 244px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-right: 1px solid $main_bg-purple;
        color: $main_onBg-textColor;

        & h1 {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;

      .drop-down-position {
        position: relative;

        .dropdown {
          position: absolute;
          top: 60px;
          background-color: #fff;
          list-style-type: none;
          width: 241px;
          border-radius: 2px;
          padding: 5px;
          box-shadow: 1px 1px 5px 1px #7d939c;

          a {
            text-decoration: none;
            color: #212628;
          }

          li {
            text-align: center;
            line-height: 2;
            color: #212628;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
              background-color: #e8edf3;
              cursor: pointer;
              color: #000;
              font-weight: 600;
            }
          }
        }

        @media screen and (max-width: 1024px) {
          .dropdown {
            top: 40px;
            width: 180px;
          }
        }

        @media screen and (max-width: 700px) {
          .dropdown {
            width: 100px;
          }
        }
      }
    }

    .anim-enter {
      opacity: 0;
      transform: scale(0.9);
    }

    .anim-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition:
        opacity 300ms,
        transform 300ms;
    }

    .anim-exit {
      opacity: 1;
    }

    .anim-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition:
        opacity 300ms,
        transform 300ms;
    }

    &__searcharea {
      max-width: 450px;
      width: 34%;
      background-color: #e8edf3;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-left: 60px;
      padding: 0 12px;
      height: 40px;
      border-radius: 5px;

      & input {
        border: none;
        background-color: transparent;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
      }

      & img {
        cursor: pointer;
      }
    }

    &__links {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      @media screen and (min-width: 1500px) {
        .link,
        .link_active {
          font-size: 20px;
        }
      }

      .link {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-radius: 8px;
        text-decoration: none;
        color: #576368;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        img {
          margin-right: 10px;

          @media screen and (max-width: 500px) {
            margin-right: 0px !important;
          }
        }
      }

      .link_active {
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 15px;
        border-radius: 8px;
        text-decoration: none;
        color: #9978e2;
        background-color: #ededed;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        img {
          margin-right: 10px;

          @media screen and (max-width: 500px) {
            margin-right: 0px !important;
          }
        }
      }

      @media screen and (max-width: 800px) {
        .link,
        .link_active {
          padding: 0 5px;
          letter-spacing: 0.3px;
          font-size: 2vw;

          img {
            margin-right: 5px;
            width: 20px;
            height: 18px;
          }
        }
      }
    }

    &__selected_user {
      height: 40px;
      width: 241px;
      border: 1px solid #73788c;
      border-radius: 2px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      cursor: pointer;

      & img {
        margin-right: 7px;
        cursor: pointer;
      }

      & p {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    @media screen and (max-width: 1024px) {
      height: 40px;

      .w344 {
        width: auto;
      }

      .w100 {
        width: auto;
      }

      &__blackArea {
        width: 70px;

        &_logo {
          width: 100%;
        }

        &_h1 {
          display: none;
          width: 0px;
        }
      }

      &__searcharea {
        height: 27px;
        margin-left: 20px;
        width: 30%;

        & input {
          font-size: 12px;
        }
      }

      &__selected_user {
        height: 27px;
        width: 180px;

        & p {
          font-size: 12px;
        }
      }

      &__links {
        .link,
        .link_active {
          padding: 5px 15px;
        }

        .link_active::after {
          top: 30px;
        }
      }
    }

    @media screen and (max-width: 700px) {
      &__selected_user {
        width: 100px;
      }
    }
  }

  &__bottom {
    z-index: 0;
    background-color: #ededed;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_links {
      display: flex;
      align-items: flex-end;
      align-self: flex-end;
      margin-left: 10px;

      .active {
        cursor: pointer;
        text-decoration: none;
        margin: 0 5px;
        margin-top: 5px;
        background-color: #fff;
        padding: 10px 10px 0 10px;
        list-style-type: none;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        align-items: center;

        div {
          border-bottom: 4px solid #9978e2;
          padding-bottom: 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          p {
            text-transform: uppercase;
            color: #000;
            font-size: 18px;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }

      .dis {
        cursor: pointer;
        text-decoration: none;
        opacity: 0.6;
        margin: 0 5px;
        margin-top: 5px;
        background-color: #fff;
        padding: 10px 10px 0 10px;
        list-style-type: none;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        display: flex;
        align-items: center;

        div {
          border-bottom: 4px solid transparent;
          padding-bottom: 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          p {
            text-transform: uppercase;
            color: #000;
            font-size: 18px;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }

    &__history {
      display: flex;
      align-items: center;
      margin-left: 30px;

      & p {
        color: #73788c;
      }

      &_date {
        margin-left: 10px;
        display: flex;
        align-items: center;

        & p {
          text-decoration: none;
          color: #333f4e;
          font-weight: 400;
          font-size: 16px;
          padding: 10px 10px 5px 10px;
          cursor: pointer;
          border-bottom: 4px solid #c8cacd;
        }

        .dis_border {
          transition:
            background-color,
            border-bottom 0.2s ease;
        }

        .dis_border:hover {
          background-color: rgb(224, 224, 224);
          border-bottom: 4px solid #a6a7a8;
        }

        .active_border {
          border-bottom: 4px solid #9978e2;
          color: #9978e2;
        }
      }
    }

    &__history_small {
      display: none;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      margin-left: auto;
      margin-right: 20px;

      span {
        margin-right: 10px;
        color: #73788c;
        font-size: 14px;
      }

      div {
        height: 50%;
        color: #9978e2;
        background-color: #eee !important;

        div {
          div {
            padding: 0;
            padding: 10px 15px;
          }

          svg {
            right: 0;
            color: #9978e2;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      &__history {
        display: none;
      }

      &__history_small {
        display: flex;
      }

      &_links {
        margin-left: 0px;

        .active,
        .dis {
          margin: 0 2px;
          margin-top: 10px;
          padding: 12px 5px 8px 5px;

          div {
            padding-bottom: 6px;

            p {
              font-size: 14px;
            }

            img {
              margin-right: 5px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 600px) {
      &_links {
        .active,
        .dis {
          div {
            p {
              font-size: 11px;
            }
          }
        }
      }
    }

    &__import {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
      padding: 25px 0;

      & img {
        margin-right: 10px;
      }

      & p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .title-block {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 30px;

      svg {
        fill: rgba(91, 93, 101, 1);
      }

      span {
        margin-left: 8px;
        font-weight: 400;
        font-size: 20px;
      }

      @media screen and (max-width: 1160px) {
        margin-left: 20px;

        span {
          font-size: 16px !important;
        }
      }

      @media screen and (max-width: 800px) {
        margin-left: 10px;

        svg {
          height: 14px;
          width: 11.2px;
        }

        span {
          font-size: 14px !important;
        }
      }
    }

    .right-notifications {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-right: 20px;

      .create-message {
        margin-left: 30px;

        @media screen and (max-width: 1024px) {
          margin-left: 10px;
        }

        button {
          display: flex;
          align-items: center;
          border: 1px solid rgb(174, 177, 192);
          background-color: transparent;
          padding: 10px 15px;
          margin: 10px 0;
          border-radius: 3px;
          cursor: pointer;
          transition:
            background-color 0.3s linear,
            border 0.3s ease;

          &:hover {
            background-color: #eee;
          }

          svg {
            margin-right: 10px;

            @media screen and (max-width: 1160px) {
              margin-right: 6px;
            }
          }

          span {
            font-weight: 400;
            font-size: 16px;

            @media screen and (max-width: 1160px) {
              font-size: 13px;
            }
          }

          &:hover {
            border: 1px solid #9978e2;
          }
        }
      }
    }
  }

  &__bottom_empty {
    width: 100%;
    background-color: #ededed;
    z-index: 0;
    height: 30px;
  }

  &__bottom_choice_merchant {
    width: 100%;
    background-color: #ededed;
    z-index: 0;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    label {
      img.shop_img {
        margin-right: 8px;
        width: 15px;
      }

      color: rgb(46, 43, 43);
      font-size: 18px;
      margin-right: 10px;
    }

    select {
      padding: 2px 10px;
      max-width: 200px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      background-color: #bde139;
      border-radius: 3px;
    }

    .cancel-icon {
      color: #000;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

.import-modal-content {
  display: flex;
  position: relative;
  min-height: 200px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading {
    width: 80%;
    font-size: 18px;
    color: #333f4e;
    text-align: center;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  .error {
    width: 80%;
    font-size: 18px;
    color: rgb(131, 42, 42);
    font-weight: 600;
    text-align: center;
  }

  .message {
    font-size: 18px;
    color: #333f4e;
    text-align: center;
    width: 90%;
    white-space: pre-line;
    line-height: 1.7;
  }

  .message-title {
    font-size: 20px;
    color: #333f4e;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0px;
    cursor: pointer;
  }
}
